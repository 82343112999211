import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/fontawesome'
import './plugins/bootstrap-vue'
import './plugins/vue-scrollto'
import './plugins/gtag'
import './plugins/vc-global'
import './plugins/luxon'
//import './plugins/advanced-cropper'
import App from './App.vue'
import router from './router.js'
import store from './store.js'

import "./assets/global.scss";
import "./assets/themes/asps.scss";

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

const envName = process.env.VUE_APP_ENV_NAME?.toLowerCase();

if (!envName.includes("local")) {
  Sentry.init({
    Vue,
    dsn: "https://9aa927ae751c466bb115ee79a5e66695@o555442.ingest.sentry.io/5689635",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: .1,
    logErrors: true,
    release: "vendor-connect@" + store.getters.appVersion,
    environment: envName ?? "unknown",
    debug: envName !== "production",
    autoSessionTracking: true,
    sendDefaultPii: true,
    tracingOptions: {
      trackComponents: true,
    },
    ignoreErrors: [
      "ResizeObserver loop limit exceeded",
      "NavigationDuplicated"
    ],
    denyUrls: []
  });
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
