<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    class="mr-1 logo"
  >
    <path
      d="M438.584,149.906A51.167,51.167,0,1,1,387.309,65l0.361,0.6a49.582,49.582,0,1,0,50.582,83.76l0.332,0.55h0ZM377.8,78.281l0.659,0.57a42.392,42.392,0,1,1,43.536,72.45l0.47,0.776A43.7,43.7,0,1,0,377.8,78.281Z"
      transform="translate(-351 -65)"
    />
  </svg>
</template>

<script>
export default {
  name: "ASPSLogo"
};
</script>
