import Vue from 'vue'

import {
    LayoutPlugin,
    ModalPlugin,
    LinkPlugin,
    BadgePlugin,
    ImagePlugin,
    CardPlugin,
    DropdownPlugin,
    ButtonPlugin,
    SidebarPlugin,
    FormPlugin,
    FormInputPlugin,
    ListGroupPlugin,
    VBScrollspyPlugin,
    EmbedPlugin,
    AvatarPlugin,
    FormCheckboxPlugin,
    FormGroupPlugin,
    AlertPlugin,
    FormRadioPlugin,
    FormTextareaPlugin,
    TableSimplePlugin,
    CollapsePlugin,
    NavbarPlugin,
    ButtonGroupPlugin,
    TooltipPlugin,
    ToastPlugin,
    FormSelectPlugin,
    FormFilePlugin,
    FormTagsPlugin
} from 'bootstrap-vue'

Vue.use(LayoutPlugin)
Vue.use(LinkPlugin)
Vue.use(BadgePlugin)
Vue.use(ImagePlugin)
Vue.use(ModalPlugin)
Vue.use(CardPlugin)
Vue.use(DropdownPlugin)
Vue.use(ButtonPlugin)
Vue.use(SidebarPlugin)
Vue.use(FormPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormRadioPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(ListGroupPlugin)
Vue.use(VBScrollspyPlugin)
Vue.use(EmbedPlugin)
Vue.use(AvatarPlugin)
Vue.use(AlertPlugin)
Vue.use(TableSimplePlugin)
Vue.use(CollapsePlugin)
Vue.use(NavbarPlugin)
Vue.use(ButtonGroupPlugin)
Vue.use(TooltipPlugin)
Vue.use(ToastPlugin)
Vue.use(FormSelectPlugin)
Vue.use(FormFilePlugin)
Vue.use(FormTagsPlugin)