<template>
  <b-table-simple
    v-if="hasItems"
    class="editor-list mb-0"
    :data-rounded-thumbs="roundedThumbnails"
  >
    <draggable tag="tbody" handle=".handle" @update="sortItems">
      <tr v-for="i in items" :key="i.title">
        <td v-if="showSorting" class="handle text-muted">
          <small><font-awesome-icon icon="bars"/></small>
        </td>
        <td v-if="hasIcons" class="icon">
          <font-awesome-icon
            :icon="[i.iconStyle ? i.iconStyle : 'fas', i.icon]"
            size="2x"
            fixed-width
          />
        </td>
        <td v-if="hasThumbs" class="thumb d-none d-sm-table-cell">
          <b-img-lazy
            v-if="i.thumbSrc && !roundedThumbnails"
            :src="i.thumbSrc"
            fluid
            class="thumb"
          />
          <vc-headshot
            v-if="i.thumbSrc && roundedThumbnails"
            :src="i.thumbSrc"
          />
        </td>
        <td>
          <h6 class="mb-0">
            {{ i[titleField] }}
          </h6>
          <p class="mb-0">
            <small>
              {{ i[descField] }}
            </small>
          </p>
        </td>
        <td
          v-if="featurable || canEdit || canDelete"
          class="actions text-right"
          :data-can-feature="featurable"
          :data-can-edit="canEdit"
          :data-can-delete="canDelete"
        >
          <div class="actions d-inline-block">
            <b-button
              v-if="featurable"
              size="sm"
              variant="outline-primary"
              @click="$emit('feature', i)"
              v-b-tooltip.hover.v-dark
              :title="(i.featured ? 'Unfeature' : 'Feature') + ' ' + itemName"
            >
              <font-awesome-icon
                :icon="[i.featured ? 'fas' : 'far', 'star']"
                fixed-width
              />
            </b-button>
            <b-button
              v-if="canEdit"
              size="sm"
              variant="outline-primary"
              @click="$emit('edit', i)"
              v-b-tooltip.hover.v-dark
              :title="'Edit ' + itemName"
            >
              <font-awesome-icon icon="pencil-alt" fixed-width />
            </b-button>
            <b-button
              v-if="canDelete"
              size="sm"
              variant="outline-danger"
              @click="deleteItem(i)"
              v-b-tooltip.hover.v-dark
              :title="'Delete ' + itemName"
            >
              <font-awesome-icon icon="trash-alt" fixed-width />
            </b-button>
          </div>
        </td>
      </tr>
    </draggable>
  </b-table-simple>
  <div v-else class="text-center text-muted">
    <b-button v-if="canAdd" variant="primary" @click="$emit('add')">
      <strong>Add {{ itemName }}</strong>
    </b-button>
  </div>
</template>

<script>
import Enumerable from "linq";

const draggable = () =>
  import(/* webpackChunkName: "draggable" */ "vuedraggable");

export default {
  name: "EditorTable",
  components: {
    draggable
  },
  props: {
    items: { type: Array },
    sortable: { type: Boolean, default: true },
    featurable: { type: Boolean },
    itemName: { type: String, default: "Item" },
    roundedThumbnails: { type: Boolean },
    canEdit: { type: Boolean, default: true },
    canDelete: { type: Boolean, default: true },
    titleField: { type: String, default: "title" },
    descField: { type: String, default: "desc" },
    canAdd: { type: Boolean, default: true }
  },
  computed: {
    hasThumbs() {
      return Enumerable.from(this.items).any(i => i.thumbSrc);
    },
    hasIcons() {
      return Enumerable.from(this.items).any(i => i.icon);
    },
    itemCount() {
      return this.items?.length ?? 0;
    },
    showSorting() {
      return this.sortable && this.itemCount > 1;
    },
    hasItems() {
      return this.itemCount > 0;
    }
  },
  methods: {
    deleteItem(item) {
      const msg = `Are you sure you want to delete this ${this.itemName.toLowerCase()}?`;
      this.$bvModal
        .msgBoxConfirm(msg, {
          title: `Delete ${this.itemName}`,
          noFade: true,
          centered: true,
          cancelVariant: "link",
          okTitle: "Delete",
          okVariant: "danger"
        })
        .then(d => {
          if (d) this.$emit("delete", item);
        });
    },
    sortItems(event) {
      this.$emit("sort", {
        oldIndex: event.oldIndex,
        newIndex: event.newIndex
      });
    }
  }
};
</script>

<style scoped lang="scss">
img.thumb {
  max-width: 110px;
  max-height: 110px;
}

p {
  line-height: 1.1;
}

div.actions {
  display: block;
}

.table {
  width: 100%;

  tr:first-child td {
    border: 0;
  }

  td {
    vertical-align: middle;
  }
  td.thumb {
    width: 100px;
  }
  td.handle {
    width: 20px;
  }
  td.actions {
    //min-width: 100px;
    white-space: nowrap;
  }

  td.icon {
    width: 50px;
  }
}

[data-rounded-thumbs] td.thumb {
  width: 55px;
  line-height: 0;
}
</style>