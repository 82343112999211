<template>
  <b-badge :variant="variant ? variant : 'dark'" :class="cssClasses">
    <font-awesome-icon v-if="icon" :icon="icon" />
    {{ name }}
  </b-badge>
</template>

<script>
export default {
  name: "VendorBadge",
  props: {
    variant: { type: String, default: "dark" },
    large: { type: Boolean },
    icon: { type: String },
    name: { type: String }
  },
  computed: {
    cssClasses() {
      return this.large ? "large" : undefined;
    }
  }
};
</script>

<style scoped lang="scss">
.large {
  font-size: 1.05em;
}
</style>
