<template>
  <h6 class="text-center text-muted">
    <font-awesome-icon
      :icon="['fad', hasError ? 'exclamation-triangle' : 'spinner-third']"
      :spin="!hasError"
      class="mr-1"
      size="lg"
    />
    <slot />
  </h6>
</template>

<script>
export default {
  name: "Loading",
  props: {
    hasError: { type: Boolean }
  }
};
</script>
