<template>
  <!--v-b-scrollspy="90"-->
  <b-list-group id="nav" class="platter px-1 px-sm-0 py-1 py-sm-3 mb-4 mb-sm-0">
    <b-list-group-item
      v-for="i in navItems"
      :key="i.href"
      :href="i.href"
      v-scroll-to="i.href"
      class="px-1 px-sm-2"
      @click="navigateTo(i)"
    >
      <font-awesome-icon
        v-if="i.icon"
        :icon="i.icon"
        class="mr-1"
        size="lg"
        fixed-width
      />
      {{ i.title }}
    </b-list-group-item>
  </b-list-group>
</template>

<script>
export default {
  name: "PlugInNav",
  props: {
    navItems: { type: Array, required: true }
  },
  methods: {
    navigateTo(e) {
      this.$emit("navigate-to", e);
    }
  }
};
</script>

<style scoped lang="scss">
#nav {
  @media (min-width: 576px) {
    position: sticky;
    top: 90px;
  }
}

.list-group {
  flex-wrap: wrap;
  flex-direction: row;

  @media (min-width: 576px) {
    flex-wrap: initial;
    flex-direction: column;
  }
}

.list-group-item {
  border-radius: 0;
  background: transparent;
  border: 0;
  font-weight: bold;
  flex-basis: 50%;
  font-size: 0.9rem;

  @media (min-width: 350px) {
    font-size: initial;
  }

  @media (min-width: 576px) {
    flex-basis: initial;
  }
}

.list-group-item.active {
  margin-top: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: 0;
  border-top-width: 0;
}
</style>