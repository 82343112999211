import Vue from "vue";
import VueGtag from "vue-gtag";

import router from "@/router.js";

Vue.use(
  VueGtag,
  {
    config: {
      id: "UA-173899832-2",
      params: { 
        'transport_type': 'beacon',
        transport: "beacon"
      }
    }
  },
  router
);
