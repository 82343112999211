<template>
  <div v-if="src" :style="style" class="profile-pic"></div>
  <div v-else class="profile-pic icon">
    <font-awesome-icon icon="user" />
  </div>
</template>

<script>
export default {
  name: "Headshot",
  props: {
    src: { type: String }
  },
  computed: {
    style() {
      return { backgroundImage: `url(${this.src})` };
    }
  }
};
</script>

<style scoped lang="scss">
.profile-pic {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-size: cover;
  background-position: 50%;
  display: inline-block;
  flex-shrink: 0;
}

.icon {
  overflow: hidden;
  font-size: 3em;
  background-color: rgba(white, 75%);
  backdrop-filter: blur(5px);
  line-height: 1.5;
  text-align: center;
}
</style>