import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
    faSearch,
    faPeopleArrows,
    faForward,
    faSeedling,
    faSyncAlt,
    faAd,
    faFileMedicalAlt,
    faHandHoldingUsd,
    faToolbox,
    faLaptop,
    faBars,
    faFilter,
    faCheck,
    faArrowCircleLeft,
    faArrowLeft,
    faArrowRight,
    faGlobe,
    faUsers,
    faEnvelope,
    faQuoteLeft,
    faQuoteRight,
    faPhone,
    faAlignLeft,
    faVideo,
    faBoxOpen,
    faHandsHelping,
    faBullhorn,
    faStar,
    faChevronDown,
    faChevronUp,
    faPencilAlt,
    faExternalLinkAlt,
    faTrashAlt,
    faPlus,
    faChartBar,
    faChartPie,
    faTable,
    faCopy,
    faCalendarAlt,
    faTools,
    faHistory,
    faChevronRight,
    faUser,
    faVial,
    faLockAlt,
    faHeart,
    faFileInvoiceDollar,
    faChartNetwork,
    faInfoCircle,
    faHands,
    faComments,
    faClipboardCheck,
    faCheckCircle,
    faFunnelDollar,
    faSyringe
} from "@fortawesome/pro-solid-svg-icons"
import {
    faStar as faStarRegular,
    faHeart as faHeartRegular
} from "@fortawesome/pro-regular-svg-icons"
import {
    faLinkedin,
    faFacebook,
    faTwitter,
    faInstagram,
    faCcMastercard,
    faWindows,
    faApple,
    faAppStoreIos,
    faYoutube
} from '@fortawesome/free-brands-svg-icons'
import {
    faSpinnerThird,
    faExclamationTriangle
} from "@fortawesome/pro-duotone-svg-icons"

library.add(
    faSearch,
    faPeopleArrows,
    faForward,
    faSeedling,
    faSyncAlt,
    faAd,
    faFileMedicalAlt,
    faHandHoldingUsd,
    faToolbox,
    faLaptop,
    faBars,
    faFilter,
    faCheck,
    faArrowCircleLeft,
    faArrowLeft,
    faArrowRight,
    faGlobe,
    faUsers,
    faEnvelope,
    faQuoteLeft,
    faQuoteRight,
    faPhone,
    faAlignLeft,
    faVideo,
    faBoxOpen,
    faHandsHelping,
    faBullhorn,
    faStar,
    faLinkedin,
    faFacebook,
    faTwitter,
    faInstagram,
    faChevronDown,
    faChevronUp,
    faPencilAlt,
    faExternalLinkAlt,
    faTrashAlt,
    faPlus,
    faChartBar,
    faChartPie,
    faTable,
    faCopy,
    faStarRegular,
    faCalendarAlt,
    faTools,
    faCcMastercard,
    faHistory,
    faChevronRight,
    faWindows,
    faApple,
    faAppStoreIos,
    faUser,
    faVial,
    faSpinnerThird,
    faYoutube,
    faLockAlt,
    faExclamationTriangle,
    faHeart,
    faHeartRegular,
    faFileInvoiceDollar,
    faChartNetwork,
    faInfoCircle,
    faHands,
    faComments,
    faClipboardCheck,
    faCheckCircle,
    faFunnelDollar,
    faSyringe
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
