const getJson = (url, token) => {
    const requestData = { headers: {} };
    if (token) {
        requestData.headers["X-API-KEY"] = token;
    }
    return new Promise((resolve, reject) => {
        let hasError = false;
        fetch(url, requestData)
        .then(
            r => {
                hasError = r.status != 200;
                return r.json()
            },
            () => reject("Error parsing JSON")
        )
        .then(
            d => {
                if (hasError) reject(d);
                else resolve(d);
            },
            () => reject("Error processing data")
        );
    });
};

const getData = (url, token) => {
    const requestData = { headers: {} };
    if (token) {
        requestData.headers["X-API-KEY"] = token;
    }
    return new Promise((resolve, reject) => {
        let hasError = false;
        fetch(url, requestData)
        .then(
            r => {
                hasError = r.status != 200;
                return r.text()
            },
            () => reject("Error parsing text")
        )
        .then(
            d => {
                if (hasError) reject(d);
                else resolve(d);
            },
            () => reject("Error processing data")
        );
    });
}

const postData = (url, data, token, dataIsJson) => {
    return new Promise((resolve, reject) => {
        let hasError = false;
        const requestData = {
            method: "POST",
            body: data,
            headers: {}
        };
        if (dataIsJson) {
            requestData.headers["Content-Type"] = "application/json";
        }
        if (token) {
            requestData.headers["X-API-KEY"] = token;
        }
        fetch(url, requestData)
        .then(
            r => {
                hasError = r.status != 200;
                return r.json()
            },
            () => reject("Error parsing JSON")
        )
        .then(
            d => {
                if (hasError) reject(d);
                else resolve(d);
            },
            () => reject("Error processing data")
        );
    });
};

const deleteData = (url, data, token, dataIsJson) => {
    return new Promise((resolve, reject) => {
        let hasError = false;
        const requestData = {
            method: "DELETE",
            body: data,
            headers: { }
        };
        if (dataIsJson) {
            requestData.headers["Content-Type"] = "application/json";
        }
        if (token) {
            requestData.headers["X-API-KEY"] = token;
        }
        fetch(url, requestData)
        .then(
            r => {
                hasError = r.status != 200;
                return r.json()
            },
            () => reject("Error parsing JSON")
        )
        .then(
            d => {
                if (hasError) reject(d);
                else resolve(d);
            },
            () => reject("Error processing data")
        );
    });
};

const postJson = (url, requestBodyData, token) => {
    return postData(url, JSON.stringify(requestBodyData), token, true);
};

const deleteJson = (url, requestBodyData, token) => {
    return deleteData(url, JSON.stringify(requestBodyData), token, true);
};

export { getJson, postJson, deleteData, postData, deleteJson, getData };
