<template>
  <ul v-if="show" class="list-inline">
    <li v-for="b in allBadges" class="list-inline-item" :key="b.name">
      <b-link
        v-if="enableLinks && (b.to || b.href)"
        :to="b.to"
        :href="b.href"
        :target="b.target"
      >
        <vendor-badge
          :variant="b.variant"
          :large="b.large"
          :icon="b.icon"
          :name="b.name"
        />
      </b-link>

      <span
        v-else-if="enableModals && b.modal"
        @click="$bvModal.show('badge-' + b.name)"
        class="modal-badge"
      >
        <vendor-badge
          :variant="b.variant"
          :large="b.large"
          :icon="b.icon"
          :name="b.name"
        />
        <b-modal
          :id="'badge-' + b.name"
          :title="b.modal.title"
          no-fade
          hide-footer
        >
          <div v-html="b.modal.copy"></div>
        </b-modal>
      </span>
      <vendor-badge
        v-else
        :variant="b.variant"
        :large="b.large"
        :icon="b.icon"
        :name="b.name"
      />
    </li>
  </ul>
</template>

<script>
import VendorBadge from "./VendorBadge";

export default {
  name: "VendorBadges",
  components: {
    VendorBadge
  },
  props: {
    vendor: { type: Object, required: true },
    enableLinks: { type: Boolean },
    enableModals: { type: Boolean }
  },
  computed: {
    show() {
      return this.allBadges.length > 0;
    },
    allBadges() {
      const globalBadges = this.$store.state.metadata?.globalVendorBadges;
      const localBadges = this.vendor?.badges;
      if (!globalBadges && !localBadges) return [];
      if (!globalBadges) return localBadges;
      if (!localBadges) return globalBadges;
      return [...globalBadges, ...localBadges];
    }
  }
};
</script>

<style scoped lang="scss">
.modal-badge {
  cursor: pointer;
}
</style>
