import Vue from 'vue'
import VueRouter from 'vue-router'

import store from "@/store.js";

Vue.use(VueRouter)

const vendorsComponent = () => import(/* webpackChunkName: "vendors" */ '@/views/Vendors.vue');

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
    meta: { requiresAuth: true, title: "Home" }
  },
  {
    path: '/category/:key',
    name: 'Category',
    component: vendorsComponent,
    props: route => ({
      filterCategory: route.query.filterCategory,
      sort: route.query.sort ?? "name",
      collectionKey: undefined,
      categoryKey: route.params.key,
      query: undefined,
      isSearch: false
    }),
    meta: { requiresAuth: true }
  },
  {
    path: '/vendor/:vendorKey',
    name: 'Vendor',
    component: () => import(/* webpackChunkName: "vendor" */ '@/views/VendorProfile.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/collection/:key',
    name: "Collection",
    component: vendorsComponent,
    props: route => ({
      group: route.query.group,
      topPicks: route.query.topPicks,
      filterCategory: route.query.filterCategory,
      sort: route.query.sort,
      collectionKey: route.params.key,
      categoryKey: undefined,
      query: undefined,
      isSearch: false
    }),
    meta: { requiresAuth: true }
  },
  {
    path: '/search/:query?',
    name: 'Search',
    component: vendorsComponent,
    props: route => ({
      filterCategory: route.query.filterCategory,
      sort: route.query.sort ?? "name",
      query: route.params.query,
      categoryKey: undefined,
      collectionKey: undefined,
      isSearch: true
    }),
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
    meta: { title: "Login" },
    props: true
  },
  {
    path: '/admin/:key?',
    name: "VendorAdmin",
    component: () => import(/* webpackChunkName: "vendorAdmin" */ '@/views/VendorAdmin.vue'),
    props: route => ({ vendorKey: route.params.key, view: undefined}),
    meta: { requiresAuth: true }
  },
  {
    path: '/admin/:key?/reports/',
    name: "VendorReports",
    component: () => import(/* webpackChunkName: "vendorAdmin" */ '@/views/VendorAdmin.vue'),
    props: route => ({ vendorKey: route.params.key, view: "Reports"}),
    meta: { requiresAuth: true }
  },
  {
    path: '/admin/:key?/editor/',
    name: "VendorEditor",
    component: () => import(/* webpackChunkName: "vendorAdmin" */ '@/views/VendorAdmin.vue'),
    props: route => ({ vendorKey: route.params.key, view: "Editor"}),
    meta: { requiresAuth: true }
  },
  {
    path: '/admin/:key?/users/',
    name: "VendorUsers",
    component: () => import(/* webpackChunkName: "vendorAdmin" */ '@/views/VendorAdmin.vue'),
    props: route => ({ vendorKey: route.params.key, view: "Users"}),
    meta: { requiresAuth: true }
  },
  {
    path: '/internal/admin/categories',
    name: "CategoryAdmin",
    component: () => import(/* webpackChunkName: "categoryAdmin" */ '@/views/CategoryAdmin.vue'),
    meta: { requiresAuth: true, title: "Category Admin" }
  },
  // {
  //   path: '/admin/:key?/subscription/',
  //   name: "VendorSubscription",
  //   component: () => import(/* webpackChunkName: "vendorAdmin" */ '@/views/VendorAdmin.vue'),
  //   props: route => ({ vendorKey: route.params.key, view: "Subscription"}),
  //   meta: { requiresAuth: true }
  // },
  {
    path: '/reset/:token',
    name: "Reset",
    component: () => import(/* webpackChunkName: "resetPassword" */ '@/views/ResetPassword.vue'),
    props: true
  },
  {
    path: '*',
    name: "RouteNotFound",
    component: () => import(/* webpackChunkName: "notFound" */ '@/views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (!savedPosition || (!savedPosition.x && !savedPosition.y)) {
      return { x: 0, y: 0 };
    } else {
      return new Promise(resolve => {
        this.app.$root.$once("triggerScroll", () => {
          resolve(savedPosition);
        })
      });
    }
  }
})

router.beforeEach((to, _, next) => {

  if (!store.state.startingRoute) store.commit("setStartingRoute", to);

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  const needsAuth = !store.getters["loggedIn"] && requiresAuth;

  if (needsAuth) {
    next({ name: "Login" });
    return;
  }

  // Page title handling source:
  // https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  //Load application metadata
  if (!store.state.metadata) {
    store.dispatch("fetchMetadata").then(() => {
      // If a route with a title was found, set the document (page) title to that value.
      if (nearestWithTitle) document.title = `${nearestWithTitle.meta?.title} | ${store.getters.appName}`;
    });
  } else {
    if (nearestWithTitle) document.title = `${nearestWithTitle.meta?.title} | ${store.getters.appName}`;
  }

  next();
});

export default router
