<template>
  <div id="app-header">
    <b-sidebar id="sidebar-1" shadow backdrop>
      <app-sidebar />
    </b-sidebar>
    <div class="spacer"></div>
    <div class="header mb-3 mb-sm-4">
      <div class="d-flex align-items-center">
        <h2 class="mb-0">
          <font-awesome-icon
            icon="bars"
            class="hamburger mr-2 mr-sm-3"
            v-b-toggle.sidebar-1
          />
        </h2>

        <b-link to="/" class="title">
          <slot name="title">
            <vc-app-title />
          </slot>
        </b-link>

        <vc-app-profile-btn class="ml-auto" :headshot-src="headshotSrc" />
      </div>
    </div>
  </div>
</template>

<script>
const AppSidebar = () =>
  import(/* webpackChunkName: "sidebar" */ "@/components/AppSidebar.vue");

export default {
  name: "AppHeader",
  components: { AppSidebar },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    headshotSrc() {
      return this.currentUser.headshot;
    }
  }
};
</script>

<style scoped lang="scss">
#app-header {
  position: sticky;
  top: 0px;
  z-index: 900;

  .spacer {
    height: 10px;
  }
}

.header {
  padding: 10px;
  //border-radius: 10px;
  margin-left: -15px;
  margin-right: -15px;

  a.title {
    text-decoration: none;
  }
}

.hamburger {
  cursor: pointer;
}
</style>