import Vue from 'vue';

import Loading from "@/components/Global/Loading";
import AppHeader from "@/components/Global/AppHeader";
import AppProfileButton from "@/components/Global/AppProfileButton";
import Headshot from "@/components/Global/Headshot";
import EditorTable from "@/components/Global/EditorTable";
import PlugInNav from "@/components/Global/PlugInNav";
import VendorBadges from "@/components/Global/VendorBadges";
import AppTitle from "@/components/Global/AppTitle";
import TextFieldWithLimit from "@/components/Global/TextFieldWithLimit";

Vue.component("vc-loading", Loading);
Vue.component("vc-app-header", AppHeader);
Vue.component("vc-app-profile-btn", AppProfileButton);
Vue.component("vc-headshot", Headshot);
Vue.component("vc-editor-table", EditorTable);
Vue.component("vc-plug-in-nav", PlugInNav);
Vue.component("vc-vendor-badges", VendorBadges);
Vue.component("vc-app-title", AppTitle);
Vue.component("vc-text-field-limit", TextFieldWithLimit);