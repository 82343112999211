<template>
  <div class="profile" :data-size="size">
    <b-dropdown
      variant="link"
      toggle-class="text-decoration-none"
      no-caret
      right
    >
      <template #button-content>
        <vc-headshot :src="headshotSrc" />
      </template>
      <b-dropdown-item v-if="isVendorAdmin" to="/admin">
        Vendor Admin
      </b-dropdown-item>
      <b-dropdown-item v-if="isSystemAdmin" to="/internal/admin/categories">
        Category Admin
      </b-dropdown-item>
      <b-dropdown-divider v-if="isVendorAdmin" />
      <b-dropdown-item
        :to="{
          name: 'Login',
          params: {
            logOut: true
          }
        }"
      >
        Log Out
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: "AppProfileButton",
  props: {
    headshotSrc: { type: String },
    size: { type: String }
  },
  computed: {
    isVendorAdmin() {
      return this.$store.state.currentUser?.canAdmin;
    },
    isSystemAdmin() {
      return this.$store.state.currentUser?.systemAdmin;
    }
  }
};
</script>

<style scoped lang="scss">
.profile {
  ::v-deep button {
    padding: 0;
  }
  .profile-pic {
    height: 30px;
    width: 30px;
    display: block;
    font-size: 1.8em;

    @media (min-width: 576px) {
      height: 40px;
      width: 40px;
      font-size: 2.2em;
    }
  }
}

.profile[data-size="lg"] {
  .profile-pic {
    height: 45px;
    width: 45px;
    font-size: 2.5em;

    @media (min-width: 576px) {
      height: 50px;
      width: 50px;
      font-size: 2.8em;
    }
  }
}
</style>